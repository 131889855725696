import 'babel-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import webViewBridge from '@vendor/webViewBridge';
import AsyncComponent from '@core/AsyncComponent';
import '@css/index.css';

const insertScript = (path,props)=>{
    let ts = document.createElement("script");
    ts.src = path;
    if(typeof props ==='object'){
        Object.keys(props).forEach((k)=>{
            ts.setAttribute(k,props[k]);
        })
    }
    let sn = document.getElementsByTagName("script")[0];
    sn.parentNode.insertBefore(ts, sn);
}
window.insertScript=insertScript;
window.PointerEvent = void 0;
window.jsNativeBridge = {};
window.addEventListener("mousewheel", (e) => {
    if (e.deltaY === 1) {
        e.preventDefault();
    }
})
let ug = window.navigator.userAgent.toLowerCase();
let hname = window.location.hostname.toLowerCase();
let isIPad = ug.indexOf('ipad')>=0;
let isAndroid = ug.indexOf('android')>=0;
let isApp = ug.indexOf('hmfapp')>=0;
let isIPod = ug.indexOf('ipod')>=0;
let isIPhone = ug.indexOf('iphone')>=0;
let isIOS = isIPad || isIPhone || isIPod;
let isDev = hname.indexOf('localhost')>=0 || hname.indexOf('dev.')>=0;

window.PLF = {
    isApp,isIPad,isIPod,isIPhone,isIOS,isDev,isAndroid
}
if(window.PLF.isApp){
    webViewBridge && webViewBridge(window);
}
insertScript(`https://turing.captcha.qcloud.com/TCaptcha.js`);
insertScript(`${window.PUBLIC_URL}/assets/js/iload_v4.1.30.js`);
// insertScript(`https://sdk.stkouyu.com/jssdk/iload_debug.js?r=${Date.now()}`);

if(ug.match(/electron/i)){
    window.electron = window.require('electron');
    window.nativeElectronSDK = window.electron.remote.getGlobal('sdk');
}
if(isDev){
    insertScript(`${window.PUBLIC_URL}/assets/js/vconsole.min.js`);
}else{
    window._hmt = window._hmt || [];
    // insertScript('https://hm.baidu.com/hm.js?2f74a0b917c5ca4456abf94e984dd443');
    // insertScript(`${window.PUBLIC_URL}/assets/js/fundebug.2.4.0.min.js`,{
    //     apikey:'9a93dfa9b5a79de1ee8b9d062b6c87715b9fc1c4442237b38c83a4bf0ad995dd',
    //     silentConsole:'true',
    //     silentDev:'true',
    //     silentPerformance:'false',
    // });
}

let C = AsyncComponent(()=>{return import("./run");});
ReactDOM.render(<C/>,document.getElementById('root'));

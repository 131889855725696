import React, { Component } from "react";

// import loading from '@img/logo_002.png';
let defOption={
    closeLoadingView:false,
}
export default (importComponent,options) => {
    const _option = Object.assign({},defOption,options);
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }
        // async componentDidMount() {
        //     let self=this;
        //     const { default: component } = await importComponent();
        //     // setTimeout(()=>{
        //     self.setState({
        //         component: component
        //     });
        //     // },30000)
        // }
        closeLoadingView(){
            let viewLoading = document.getElementById('loading');
            if(viewLoading){
                viewLoading.style.display='none';
                viewLoading.remove();
            }
        }
        componentDidMount() {
            let self=this;
            importComponent().then(({ default: component } )=>{
                // setTimeout(()=>{
                    self.setState({
                        component: component
                    },()=>{
                        if(_option.closeLoadingView){
                            self.closeLoadingView();
                        }
                    });
                // },500)
            });
        }
        render() {
            let C = this.state.component;
            return C ? <C {...this.props} /> :null;
                {/*<div className={'app-loading-page'}>*/}
                {/*<img className={''} alt={''} src={loading}/>*/}
                {/*<p>页面加载...</p>*/}
            {/*</div>;*/}
        }
    }

    return AsyncComponent;
}